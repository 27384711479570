import {
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import iconDependency from '@/dependencies/iconDependency'
import { authUseCase } from '@/usecase'
import apiUseCase from '@/usecase/apiUseCase'
// import {
//   getListAgent,
//   openTicketUseCase,
//   courierUseCase,
//   globalSettingUseCase
// } from '@/domain/usecase'
import environment from '@/configs/environtment'
// import { cloneDeep, isEmpty } from 'lodash'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { isNull } from 'lodash'

const modules = 'deliveryItem'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const app = getCurrentInstance()
    const listGlobalSetting = ref([])
    const icons = ref(iconDependency()) as any
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const checkResponse = (data: any) => {
      $toast.add({
        severity: 'error',
        detail: data.message,
        group: 'bc',
        life: 3000
      })
    }
    const login = async () => {
      const data = new URLSearchParams() as any
      const allowedRole = ['admin', 'roster', 'setoran', 'adminkontrol', 'external']
      const token = localStorage.getItem('ut')
      if (!token) {
        const payload = {
          code: route.query.code,
          client_id: environment.getHydraClientId(),
          client_secret: environment.getHydraClientSecret(),
          redirect_uri: `${window.location.origin}/callback`,
          grant_type: environment.getHydraGrantType()
        }
        store.dispatch('processGetToken', payload)
          .then(async (response) => {
            await store.dispatch('fetchProfile')

            if (!allowedRole.includes(store.state?.userProfile?.RolesName.toLowerCase())) {
              $toast.add({
                severity: 'error',
                detail: 'Anda tidak memiliki hak akses. Harap hubungi administrator',
                group: 'bc',
                closable: false,
                life: 3000
              })
              store.dispatch('logoutAccount')
              router.push({
                name: 'Home'
              })
            }
            window.location.href = '/hjp/'
            console.log('response processGetToken', response)
          })
          .catch((err) => {
            store.dispatch('logoutAccount')
            $toast.add({
              severity: 'error',
              detail: 'Token Kadaluwarsa',
              group: 'bc',
              life: 3000
            })
            setTimeout(() => {
              router.push({
                name: 'Home'
              })
            }, 500)
          })
      } else {
        await store.dispatch('logoutAccount')
        router.push({
          name: 'Home'
        })
      }
    }

    onMounted(() => {
      // console.log('ini router', Object.keys(route.query))
      if (Object.keys(route.query).length > 0) {
        login()
      } else {
        store.dispatch('logoutAccount')
        localStorage.clear()
        router.push({
          name: 'Home'
        })
      }
    })
    return {}
  }
}
